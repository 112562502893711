<template>
  <v-dialog
    transition="slide-x-reverse-transition"
    v-model="dialog"
    max-width="900px"
    persistent
    @keydown.enter="sendMessage"
  >
    <v-card>
      <v-card-title class="px-3 py-2 secondary-background" >
        <v-layout v-if="firstDialog" align-center>
          <v-flex grow>
            <v-textarea
              id="dialog-input"
              ref="input_area"
              v-model="search"
              label="Buscar por nome ou utilização"
              autofocus
              rows="1"
              @keydown.enter="$event.preventDefault()"
              @keydown.up="$event.preventDefault()"
              @keydown.down="$event.preventDefault()"
              @keyup.enter="sendMessage"
              auto-grow
            ></v-textarea>
          </v-flex>

          <v-btn icon @click="cancelButton">
            <v-icon color="primary" v-if="search">backspace</v-icon>
            <v-icon color="primary" v-else>close</v-icon>
          </v-btn>
        </v-layout>
        <v-layout v-else justify-space-between>
          <v-btn icon @click="back">
            <v-icon color="primary">keyboard_arrow_left</v-icon>
          </v-btn>
          <span class="macro-title">{{macroName}}</span>
          <v-btn icon @click="cancelButton">
            <v-icon color="primary">close</v-icon>
          </v-btn>
        </v-layout>
      </v-card-title>
      <v-card-text v-if="search.length>0" class="secondary-background primary--text">
        <v-layout
          align-center
          style="max-height: 400px; overflow-y: auto"
          class="macros "
          wrap
          v-if="firstDialog && macrosList"
        >
          <div class="macro-card-container" v-for="macro in macrosList" :key="macro.id">
            <div
              class="macro-card"
              @keypress.enter="selectedMacro(macro)"
              @click="selectedMacro(macro)"
              tabindex="-1"
            >
              <div class="primary_item">{{ macro.name }}</div>
              <div class="secundary_item">{{ macro.key }}</div>
            </div>
          </div>
        </v-layout>

        <div v-else tabindex="-1" id="preview-container" style="outline: none">
          <v-layout align-center style="max-height: 400px; overflow-y: auto" wrap>
            <div class="preview-container">
              <div v-if="hasVariables" class="inputs-container">
                <div v-for="(field,index) in inputsMapGlobal" :key="(field,index)">
                  <v-text-field
                    required
                    v-model="inputsMapGlobal[index]"
                    :label="index"
                    :rules="[v => !!v || 'Este campo é obrigatório']"
                    @keydown="valueChanged(index,field)"
                  ></v-text-field>
                </div>
              </div>
              <div class="messages-container secondary-background" style="overflow-y: auto">
                <div class="message sent" v-for="(reply,index) in repliesList" :key="(reply,index)">
                  <div class="message-container">
                    <div class="text-container">
                      <span style="margin: 5px;" v-html="matchVariables(index)"></span>
                    </div>
                    <div class="image-container">
                      <img
                        ng-src="https://s3-sa-east-1.amazonaws.com/gorillascode-auth/apps/2.png"
                        title="Logo"
                        src="https://s3-sa-east-1.amazonaws.com/gorillascode-auth/apps/2.png"
                        style="width: 100%"
                      />
                    </div>
                  </div>
                  <div class="time-container">
                    <span>há poucos segundos</span>
                  </div>
                </div>
              </div>
            </div>
          </v-layout>
          <div class="buttonSubmitContainer">
            <v-btn
              :disabled="!sendForm()"
              color="buttonSubmit"
              text
              @click="sendMessage()"
            >Enviar</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- <v-card class="selectedReply" v-if="selectedReplyDialog"> -->
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  created() {
    window.addEventListener("keyup", this.onkeyPress);
  },
  data() {
    return {
      dialog: false,
      firstDialog: false,
      repliesList: [],
      macros: null,
      macroName: "",
      search: "",
      macroID: 0,
      inputsMapGlobal: {},
      propertyChangedFromKeyboard: false,
      replacedMessagesArray: [],
      focusIndex: 0,
      hasVariables: false,
      macrosList: [],      
    };
  },
  updated() {
    this.dialogInput = document.getElementById("dialog-input");
    this.focusInputArea();
    if (!this.dialog) {
      this.$emit("close-dialog");
    }
  },

  destroyed: function() {
    window.removeEventListener("keyup", this.onkeyPress);
  },

  methods: {
    open() {
      this.dialog = true;
      this.firstDialog = true;
      this.focusInputArea();
    },
    focusMacro(macroIndex){
      const elements = document.querySelectorAll(".macro-card");
      elements.length && elements[macroIndex].focus();
    },
    focusNextMacro(){
      this.focusIndex = (this.focusIndex + 1) % this.macrosList.length;
      this.focusMacro(this.focusIndex);
    },
    focusPreviousMacro(){
      this.focusIndex = (this.focusIndex + this.macrosList.length - 1) % this.macrosList.length;
      this.focusMacro(this.focusIndex);
    },
    catchKeyAction(key) {

      if (this.$refs.input_area) {
         this.$refs.input_area.$el.focus();
      }

      if(this.macrosList.length){
        if (key.key == "ArrowDown") {
          this.focusNextMacro();
          return; 
        }
        if (key.key == "ArrowUp") {
          this.focusPreviousMacro();
          return;
        }
      }


      if (key.key == "Escape" && this.firstDialog && this.search.length > 0) {
        this.search = "";
        return;
      }
      if (key.key == "Escape" && !this.firstDialog) {
        this.firstDialog = true;
        return;
      }
      if (key.key == "Escape" && this.firstDialog && this.search.length == 0) {
        this.dialog = false;
        return;
      }
    },
    cancelButton() {
      if (!this.firstDialog) {
        this.firstDialog = true;
        return;
      }
      if (this.search.length > 0) {
        this.search = "";
        return;
      }
      this.dialog = false;
    },

    back() {
      this.firstDialog = true;
    },

    selectedMacro(selected) {
      this.repliesList = selected.replies;
      this.firstDialog = false;
      this.macroName = selected.name;
      this.macroID = selected.id;
      this.inputsMap = {};
      this.inputsMapGlobal = {};
      this.propertyChangedFromKeyboard = false;
      this.hasVariables = false;
    },
    sendMessage() {
      if (this.firstDialog && this.macrosList) {        
        this.selectedMacro(this.macrosList[this.focusIndex]);
        return;
      }

      if (!this.repliesList || this.firstDialog) return;

      let dictionary = this.makeMacrosDictionary(); 
      this.$emit("send-message", {
        macrosDictionary: dictionary,
        macroID: this.macroID
      });

      this.firstDialog = true;
      this.dialog = false;
    },
    focusCancelArea() {
      setTimeout(() => {
        let cancelArea = document.querySelectorAll("#preview-container")[0];
        if (cancelArea) cancelArea.focus();
      }, 10);
    },
    focusInputArea() {
      if (this.$refs.input_area) {
        this.dialogInput.focus();
      }
    },
    matchVariables(index) {
      let matchString = /(\$\S+\b)/gi;
      let variablesMap = {};
      let inputsMap = {};
      let globalInputReference = this.inputsMapGlobal;
      let repliesListReference = this.repliesList;
      let messageReplaced = {};
      let messageReplacedtoSend = {};
      if (!this.propertyChangedFromKeyboard) {
        messageReplaced = repliesListReference[index].replace(
          matchString,
          matched => {
            inputsMap[matched.slice(1)] = this.getMatchedVariable(
              matched,
              variablesMap
            );
            this.hasVariables = true;
            if (inputsMap[matched.slice(1)])
              return (
                "<strong><i>" + inputsMap[matched.slice(1)] + "</i></strong>"
              );
            else return "<strong><i>" + matched.slice(1) + "</i></strong>";
          }
        );
        messageReplacedtoSend = repliesListReference[index].replace(
          matchString,
          matched => {
            inputsMap[matched.slice(1)] = this.getMatchedVariable(
              matched,
              variablesMap
            );
            this.hasVariables = true;
            return variablesMap[matched.slice(1)];
          }
        );
      } else {
        messageReplaced = repliesListReference[index].replace(
          matchString,
          matched => {
            matched = matched.slice(1);
            inputsMap[matched] = globalInputReference[matched];
            let returnString =
              globalInputReference[matched] == "" ||
              globalInputReference[matched] == undefined
                ? matched
                : globalInputReference[matched];
            return "<strong><i>" + returnString + "</i></strong>";
          }
        );
        messageReplacedtoSend = repliesListReference[index].replace(
          matchString,
          matched => {
            matched = matched.slice(1);
            inputsMap[matched] = globalInputReference[matched];
            let returnString =
              globalInputReference[matched] == "" ||
              globalInputReference[matched] == undefined
                ? matched
                : globalInputReference[matched];
            return returnString;
          }
        );
      }
      if (index + 1 === this.repliesList)
        this.propertyChangedFromKeyboard = false;
      for (let key in inputsMap) {
        this.$set(this.inputsMapGlobal, key, inputsMap[key]);
      }
      this.replacedMessagesArray[index] = messageReplacedtoSend;
      return messageReplaced;
    },
    valueChanged(index, value) {
      this.inputsMapGlobal[index] = value;
      this.propertyChangedFromKeyboard = true;
    },
    getMatchedVariable(matched, variablesMap) {
      if (matched.slice(1).indexOf(".") > -1) {
        let match = matched.slice(1).split(".");
        if (variablesMap[match[0]] && variablesMap[match[0]][match[1]])
          return variablesMap[match[0]][match[1]];
        return "";
      } else {
        if (!variablesMap[matched.slice(1)]) return "";
        return variablesMap[matched.slice(1)];
      }
    },
    makeMacrosDictionary() {
      let dict = {};
      for (let key in this.inputsMapGlobal) {
        dict[key] = this.inputsMapGlobal[key];
      }
      return dict;
    },
    sendForm() {
      for (let input in this.inputsMapGlobal) {
        if (!this.inputsMapGlobal[input]) return false;
      }
      return true;
    },
    onkeyPress(event) {
      this.catchKeyAction(event);
    },
    formatQueryToLower: function(query){
      let lowerQuery = query.toLowerCase();
      lowerQuery = lowerQuery.replace(/[àáâãä]/,"a");
      lowerQuery = lowerQuery.replace(/[èéêẽë]/,"e");
      lowerQuery = lowerQuery.replace(/[ìíîĩï]/,"i");
      lowerQuery = lowerQuery.replace(/[òóôõö]/,"o");
      lowerQuery = lowerQuery.replace(/[ùúûũü]/,"u");
      lowerQuery = lowerQuery.replace(/[ç]/,"c");

      return lowerQuery;
    }
  },

  computed: {
    ...mapGetters('rasaQuickReplyList', {
      macroListFromStore: 'list'
    }),
    ...mapGetters('rasaConversation',{
      currentConversation: "currentConversation",
    }),
    ...mapGetters("rasaServices", {
      currentService: "currentService",
    }),
  },
  watch: {
    dialog() {
      if (this.dialog == false) {
        Object.assign(this.$data, this.$options.data());
      }
    },
    firstDialog() {
      this.firstDialog ? this.focusInputArea() : this.focusCancelArea();
      if (!this.firstDialog) {
        setTimeout(() => {
          let element = document.querySelectorAll(
            ".inputs-container .v-input input"
          )[0];
          if (element) element.focus();
        }, 50);
      }
    },
    search: function(value) {
      if (value) {
        const formattedValue = this.formatQueryToLower(value);
        const regex = new RegExp(formattedValue, "i");

        this.macrosList = this.macroListFromStore.filter(
          macro => (
            (regex.test(this.formatQueryToLower(macro.name)) ||
            regex.test(this.formatQueryToLower(macro.key))) &&
            macro.active === true
            )
        );
        console.log(this.macrosList)
        this.focusIndex = 0;
        this.focusMacro(this.focusIndex);
        
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-dialog--active {
  transition: width 0.2s, height 0.2s;
  position: fixed;
  top: 20%;
  max-width: 70%;
  .v-card {
    background: unset;
    .v-card__title {
      background: white;

      border-radius: 1em 1em 0 0 !important;
      label {
        font-size: larger;
      }
    }
    .v-card__text {
      background: white;
      border-radius: 0 0 1em 1em !important;
    }
  }
}

.selectedReply {
  min-width: 30em;
}

.v-dialog .v-card {
  overflow: hidden;
}
.macro-title {
  color: var(--mdc-theme-text-primary-on-background) !important;
  margin-top: 0.6em;
  font-size: initial;
}
.macros {
  display: flex;
  align-items: center;
}

.macro-card-container {
  display: block;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  overflow: hidden;
  width: 100%;
  &:hover {
    cursor: pointer;
    background-color: rgba(138, 138, 138, 0.13);
    transition: opacity 15ms linear, background-color 200ms linear;
  }
  :focus {
    background-color: rgb(138, 138, 138);
    transition: opacity 15ms linear, background-color 200ms linear;
  }
}

.macro-card {
  // font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 10px;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  outline: none !important;
}

.v-card__title {
  color: black;

  .v-icon {
    color: black;
  }
}

.message {
  color: #505050;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
}

.message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  display: flex;
  float: right;
  flex-direction: column;
  width: 100%;

  .text-container {
    width: 95%;
    display: flex;
    span {
      padding: 10px;
      max-width: 100%;
    }
  }
  .image-container {
    width: 5%;
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    flex-direction: column;
  }
  .message-container {
    flex: 1;
    display: flex;
  }
  .time-container {
    display: flex;
    justify-content: flex-end;
    span {
      font-style: italic;
      font-size: small;
    }
  }
}

.messages-container {
  align-items: center;
  background: #f9f9f9;
  padding: 1em;
  border-radius: 2em;
}

.buttonSubmitContainer {
  display: flex;
  justify-content: space-evenly;
  padding-top: 1em;
  button {
    border-radius: 1em;
    flex: 1;
  }
}

.buttonSubmit {
  background: #00e58d !important;
  color: #00606a !important;
  font-size: larger !important;
}
.v-btn--disabled {
  font-size: larger !important;
}
.primary_item {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  line-height: normal;
  display: block;
}
.secundary_item {
  // font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  line-height: normal;
  display: block;
}
.preview-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.inputs-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  div {
    margin: 0 1em 0 0;
  }
}
.messages-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}
</style>
